@import 'mixins';
@import 'colours';

html,
body,
p {
    padding: 0;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: 0.05em;
    color: #333;
}

body {
    padding-top: 142px;
}

.top-nav {
    @include flexbox-container;
    @include flex-align-items-center;
    @include flex-justify-content-center;
}

.nav-title {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
}

.nav-btn {
    height: 36px;
    width: 36px;
    margin: 0 0.5em;
    font-size: 1.7em;
    padding: 0;
    background-color: transparent;
    border: none;
    color: #444;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    padding: 0;
    text-align: center;
    cursor: pointer;
}

.nav-btn i {
    margin: 0;
    padding: 0;
}

.logo {
    width: 25%;
    margin: 2em auto;
    display: inline-block;
}

.date-title {
    display: inline-block;
    padding: 0;
    margin: 0;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-grow: 0;
    -ms-flex-item-align: center;
    align-self: center;
}

.nav-btn:hover {
    cursor: pointer;
    color: gold;
}

.top-nav,
.weekday {
    @include flexbox-container;
    @include flex-align-items-center;
    @include flex-justify-content-center;

    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    height: 64px;
    vertical-align: middle;
    position: fixed;
    width: 100%;
    padding: 0;
    margin: 0;
    top: 0;
    bottom: 0;
    background-color: white;
    z-index: 100;
}

.weekday {
    @include flexbox-container;
    @include flex-align-items-center;
    @include flex-justify-content-center;

    background-color: gold;
    z-index: 99;
    top: 64px;
    height: 76px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
}

.d-sel {
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.day {
    @include flexbox-container;
    @include flex-align-items-center;
    @include flex-justify-content-center;
    width: 13%;
    text-align: center;
    margin: 0 auto;
    line-height: 48px;
    font-weight: 600;
    height: 48px;
    width: 48px;
    border-radius: 24px;
    margin: 6px auto 2px auto;
}

.day.selected,
.d-sel:hover > .day {
    background-color: white;
    cursor: pointer;
}

.day.today .mini-date {
    text-decoration: underline;
}

.project-sel {
    @include flexbox-container;
    @include flex-align-items-center;
    @include flex-justify-content-center;

    border-bottom: 1px solid #ccc;
    height: 72px;
    cursor: pointer;
}

.project-time {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-preferred-size: 4em;
    flex-basis: 4em;
    -webkit-flex-grow: 0;
    -webkit-flex-shrink: 0;
    -webkit-flex-basis: 4em;
    -ms-flex-item-align: center;
    align-self: center;
    text-align: right;
    font-size: 1.25em;
    padding-right: 1rem;
}

.project-text {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-grow: 1;
    -ms-flex-item-align: center;
    align-self: center;
}

.project-text span {
    clear: both;
    display: block;
    color: #444;
}

.project.disabled .project-text span {
    color: #999;
}

.project.disabled .project-sel {
    cursor: default;
}

.cc-name {
    font-size: 0.6em;
    font-style: italic;
    padding-left: 0.75rem;
}

.add-entry-form__item,
.pr-name {
    font-size: 1.1em;
    padding-left: 0.75rem;
}

.ph-name {
    font-size: 0.7em;
    padding-left: 1rem;
}

.project.expanded > .project-sel {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    border-bottom: 0;
    z-index: 10;
    position: relative;
    background-color: gold;
}

.project-dropdown {
    background-color: #f2f2f2;
    box-shadow: inset 0 13px 30px rgba(97, 143, 176, 0.1);
    border-bottom: solid 1px #ccc;
}

.project-options {
    @include flexbox-container;
    @include flex-align-items-center;
    @include flex-justify-content-center;

    height: 96px;
    z-index: 1;
    color: #444;
    font-size: 2em;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-direction: row;
    border-color: #444;
}

.project-options .pr-opt {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-grow: 1;
    -ms-flex-item-align: center;
    align-self: center;
    text-align: center;
    cursor: pointer;
}

.project-options .pr-opt:hover {
    color: #292929;
    border-color: #292929;
}

.project-options .pr-opt:hover {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-grow: 1;
    -ms-flex-item-align: center;
    align-self: center;
    text-align: center;
}

.project-options .pr-opt-icon {
    display: inline-block;
    border: solid 2px;
    width: 48px;
    height: 48px;
    border-radius: 30px;
    vertical-align: middle;
    text-align: center;
}

.pr-opt-icon.notes .fa {
    position: relative;
    top: 1px;
    font-size: 0.9em;
}

.pr-opt-icon.input .fa {
    position: relative;
    top: 1px;
}

.pr-opt-icon.timer .fa-play {
    position: relative;
    left: 3px;
    font-size: 0.9em;
}

.pr-opt-icon.timer .fa-pause {
    position: relative;
    top: -1px;
    font-size: 0.8em;
}

.pr-opt-icon.time-options .fa-code-fork {
    position: relative;
    top: 1px;
    left: 2px;
}

.pr-opt-label {
    display: block;
    font-size: 0.5rem;
    text-transform: uppercase;
    margin-top: 0.4rem;
    font-weight: bold;
}

.project-timer {
    border-bottom: 1px solid #ccc;
    text-align: center;
    vertical-align: middle;
    font-size: 3em;
    padding: 1.5rem;
}

.project-dropdown.two-btn > .project-options {
    padding: 0 2.5rem;
}

.project-note {
    padding: 1.5rem;
    border-bottom: 1px solid #ccc;
}

.project-note > textarea {
    width: 100%;
    max-width: 100%;
    background-color: transparent;
    border: 0;
    font-size: 1.1rem;
    font-style: italic;
    color: #444;
    resize: none;
    text-align: center;
}

.project-note > textarea:focus {
    outline: 0;
}

.project-locked {
    padding: 1.5rem;
    text-align: center;
    font-size: 1.3em;
    text-transform: uppercase;
    font-weight: 600;
}

.project-input {
    border-bottom: 1px solid #ccc;
    padding: 1.5rem 0;
}

.project-input input {
    width: 100%;
    text-align: center;
    font-size: 3rem;
    border: 0;
    color: #444;
    background-color: transparent;
    display: inline-block;
}

.project-input input:focus {
    outline: 0;
}

.day-hours {
    font-size: 0.8em;
    margin-bottom: 2px;
}

.day-circle {
    display: inline-block;
    margin-top: -5px;
}

.mini-day {
    font-size: 0.7em;
    margin: 0;
    display: block;
    line-height: 1.5em;
}

.mini-date {
    margin: 0;
    display: block;
    line-height: 1em;
}

.d-sel.selected .day-hours {
    font-weight: 600;
}

.project-btn {
    text-align: center;
    font-weight: 600;
    padding: 1em;
    cursor: pointer;
    margin: 1em;
    background-color: white;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.2);
    border: solid 1px #ccc;
    text-transform: uppercase;
}

.project-btn:hover {
    background-color: #f7f7f7;
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2);
    border: solid 1px #ccc;
    text-transform: uppercase;
}

.login {
    font-size: 2em;
    margin-top: 1.5em;
}

.login input {
    width: 80%;
    font-size: 0.5em;
    font-style: italic;
    letter-spacing: 0.1em;
    padding: 0.5em;
    margin: 1em;
}

.login button {
    width: 80%;
    font-size: 0.5em;
}

.time-entry-switches {
    @include flexbox-container;

    padding: 1em;
    border-bottom: solid 1px #ccc;
    text-align: center;
    font-size: 1.5em;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.time-entry-switch {
    display: inline-block;
    cursor: pointer;
}

.time-entry-switch i {
    margin-right: 0.2em;
}

.coincraft-gold {
    color: $gold;
}
